<template>
  <authorization-layout>
    <template>
      <div class="container px-2">
        <forgot-password-modal :is-modal="false"></forgot-password-modal>
      </div>
    </template>
  </authorization-layout>
</template>

<script>
import UnauthorizedRouteMixin from "@/web/mixins/UnauthorizedRouteMixin";
import AuthorizationLayout from "@/web/components/shared/layout/AuthorizationLayout";
import ForgotPasswordModal from "@/web/views/modals/ForgotPasswordModal";

export default {
  name: "ForgotPassword",
  mixins: [UnauthorizedRouteMixin],
  components: {
    ForgotPasswordModal,
    AuthorizationLayout,
  },
};
</script>

<style lang="scss" scoped>
.container {
  max-width: 650px !important;
}
</style>
