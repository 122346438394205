<template>
  <authorization-layout>
    <template>
      <div class="container px-2">
        <register-modal :is-modal="false"></register-modal>
      </div>
    </template>
  </authorization-layout>
</template>

<script>
import AuthorizationLayout from "@/web/components/shared/layout/AuthorizationLayout";
import RegisterModal from "@/web/views/modals/RegisterModal";
import UnauthorizedRouteMixin from "@/web/mixins/UnauthorizedRouteMixin";

export default {
  name: "Register",
  mixins: [UnauthorizedRouteMixin],
  components: {
    RegisterModal,
    AuthorizationLayout,
  },
};
</script>

<style lang="scss" scoped>
.container {
  max-width: 650px !important;
}
</style>
