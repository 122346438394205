<template>
  <authorization-layout>
    <template>
      <div class="container px-2">
        <login-modal :is-modal="false"></login-modal>
      </div>
    </template>
  </authorization-layout>
</template>

<script>
import AuthorizationLayout from "@/web/components/shared/layout/AuthorizationLayout";
import LoginModal from "@/web/views/modals/LoginModal";
import UnauthorizedRouteMixin from "@/web/mixins/UnauthorizedRouteMixin";

export default {
  name: "Login",
  mixins: [UnauthorizedRouteMixin],
  components: {
    LoginModal,
    AuthorizationLayout,
  },
};
</script>

<style lang="scss" scoped>
.container {
  overflow-y: visible;
  max-width: 650px !important;
}
</style>
